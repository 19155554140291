<template>
  <div class="copy-id">
    <div class="copy-id__field">
      <form-input-text
        :placeholder="shortenedSecurityCode"
        :label="label"
        :name="name"
        isDisabled
      />
    </div>
    <button
      class="copy-id__btn"
      :disabled="!code"
      @click="emit('copyId', code)"
    >
      {{ btnText }}
    </button>
  </div>
</template>

<script setup lang="ts">
  const SECURITY_CODE_LIMIT = 14;
  const emit = defineEmits(['copyId']);
  const props = defineProps<{
    label: string;
    code?: string | number;
    btnText: string;
    name: string;
  }>();

  const shortenedSecurityCode = computed(() => {
    const code = String(props.code || '');
    return code.length > SECURITY_CODE_LIMIT ? `${code.slice(0, SECURITY_CODE_LIMIT)}...` : code;
  });

</script>

<style src="~/assets/styles/components/button/copy-id.scss" lang="scss" />
